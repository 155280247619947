import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../../Pages/Home/HomePage";
import TeamPage from "../../Pages/Team/TeamPage";
import PricePage from "../../Pages/Prices/PricePage";
import ServicesPage from "../../Pages/Services/ServicesPage";
import ContactsPage from "../../Pages/Contacts/ContactsPage";
export default function Main() {

  return (
    <main className="main">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/team" element={<TeamPage />} />
        <Route path="/prices" element={<PricePage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/contacts" element={<ContactsPage />} />
      </Routes>
    </main>
  );
}
