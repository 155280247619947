import React from "react";
import { Link } from "react-router-dom";
import SvgIconComponent from "../Svg-Icon/SvgIconComponent";
import "./serviceChoiseElement.css";

const ServiceChoise = ({ props }) => {
  return (
    <section className="service-choise"><div className="service-choise-content">
      <div className="service-page-icon">
        <SvgIconComponent title={props.iconTitle}/>
      </div>
      <div className="service-choise-title">{props.serviceName}</div>
      <div>Paslaugos kaina: {props.servicePrice} €</div>
      <hr />
      <div className="service-page-description">{props.serviceDescription}</div></div>
      <Link to="/contacts" className="designed-button" role="button">
        <span className="reddish-button">GAUTI PASIŪLYMĄ</span>
      </Link>
    </section>
  );
};

export default ServiceChoise;
