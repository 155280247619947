import React, { useLayoutEffect } from "react";
import ServiceChoise from "../../components/Service-choise/ServiceChoiseElement";
import "./servicesPage.css";

const ServicesPage = () => {
  const serviceList = [
    {
      id: 1,
      iconTitle: "camera",
      serviceName: "Profesionali fotosesija",
      servicePrice: "70-220",
      serviceDescription:
        "Nusprendėte parduoti ar išnuomoti būstą ar komercines patalpas? O gal tiesiog norite įamžinti savo interjerą? Profesionalią fotosesiją galite užsakyti pas mus. Kas sudaro kainą? 1. Profesionalaus interjero fotografo vizitas į objektą. 2. Objekto fotosesija įvairiais rakursais. 3. Drono naudojimas filmavimui. 4. Vaizdo klipo montavimas.",
    },{
      id: 2,
      iconTitle: "feather",
      serviceName: "Nuomos skelbimo kūrimas ir publikavimas",
      servicePrice: "80-160",
      serviceDescription:
        "Į visus populiariausius lietuviškus nekilnojamojo turto portalus ir socialinius tinklus dedami skelbimai bei perkamos skelbimų iškėlimo paslaugos. Kuriamas tekstas bei objekto aprašymas.",
    },{
      id: 3,
      iconTitle: "contract",
      serviceName: "Profesionalios sutarties parengimas",
      servicePrice: "80",
      serviceDescription:
        "Paruošiama profesionali nuomos sutartis atitinkanti objektų savininkų interesus ir lūkesčius.",
    },{
      id: 3,
      iconTitle: "person",
      serviceName: "Nuomininkų atranka",
      servicePrice: "nuo 200",
      serviceDescription:
        "Daroma nuomininkų atranka pagal objektų savininkų kriterijus. Objekto apžiūros daromos tol kol bus rastas visus kriterijus atitinkantis nuomininkas.",
    },{
      id: 4,
      iconTitle: "signing",
      serviceName: "Sutaries pasirašymo kontrolė",
      servicePrice: "60",
      serviceDescription:
        "Sutarties pasirašymo metu dalyvaujame ir pildome objekto priėmimo- perdavimo aktą. Surašome komunalinių paslaugų faktinius skaitliukų parodymus, fiksuojame daiktus bei baldus perleidžiamus nuomininkams.",
    },{
      id: 5,
      iconTitle: "houseplus",
      serviceName: "Buto fizinės būklės priežiūra",
      servicePrice: "nuo 60",
      serviceDescription:
        "Lankomės išnuomotuose objektuose. Tikriname tvarką, buitinius prietaisus. Išsikėlus nuomininkams daromas kapitalinis buto valymas.",
    },{
      id: 6,
      iconTitle: "invoice",
      serviceName: "Mokesčių mokėjimų kontrolė",
      servicePrice: "80",
      serviceDescription:
        "Kiekvieną mėnesį deklaruojame komunalinius mokesčius. Siunčiame suvartojimo ataskaitas nuomininkams bei būstų savininkams.",
    },{
      id: 7,
      iconTitle: "conversation",
      serviceName: "Komunikacija su nuomininkais",
      servicePrice: "50",
      serviceDescription:
        "Reguliariai bendraujame su nuomininkais, tikriname, kad nevėluotų mokėti už nuomą ir komunalinius mokesčius. Sprendžiame įvairias susidariusias problemas.",
    },{
      id: 8,
      iconTitle: "repair",
      serviceName: "Gedimų šalinimo organizavimas",
      servicePrice: "50",
      serviceDescription:
        "Atsiradus buitinės technikos gedimams ar avarinėms situacijoms, nedelsiant sprenžiame susidariusias problemas, kviečiame profesionalius meistrus gedimams šalinti. Rūpinamės,kad gedimai būtų pašalinti per kuo trumpesnį laiką.",
    },
  ];
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="price-page-container">
      <img
        src="/img/services-background.jpg"
        alt="background"
        className="services--background"
      />
      <div className="shader">
        <div className="price-page-content">
          <h1 className="full-screen-heading">Paslaugos</h1>
          <div className="service-page-choises">
          {serviceList.map(item => (<ServiceChoise key={item.id} props={item} />))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesPage;
