import React, { useLayoutEffect } from "react";
import "./teamPage.css";
import SvgIconComponent from "../../components/Svg-Icon/SvgIconComponent";

const TeamPage = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="team-page-container">
      <img
        src="/img/buildings.png"
        alt="background"
        className="parallax--background"
      />
      <img
        src="/img/handshake.png"
        alt="application"
        className="parallax--object"
      />
      <div className="team-page-content shader">
        <h1 className="full-screen-heading">KOMANDA</h1>
        <div className="team-member">
          <img
            src="/img/Marius.jpg"
            alt="Marius Lepeška"
            className="team-member-picture"
          />
          <div className="team-member-description">
            <div className="team-member-description-header">
              <div>
                <p>Apie</p>
                <h2>Marius Lepeška</h2>
              </div>
              <img
                src="/img/Marius-portrait.png"
                alt="Marius Lepeška"
                className="team-member-description-picture"
              />
            </div>
            <p>
              MB ,,Romara,, įmonės direktorius ir vienas iš prekinio ženklo
              „Nuomos Ekspertas” įkūrėjų.
            </p>
            <p>
              Daug metų dirbdamas pardavimų srityje įgijo įgūdžių reikalingų
              investavime bei versle.
            </p>
            <p>
              Reguliariai investuoja į nekilnojamąjį turtą gaunant pasyvių
              pajamų srautą.
            </p>
            <p>
              Visas žinias ir įgūdžius nusprendė sutelkti į pagrindinę sritį –
              Nekilnojamojo turto nuomą ir investavimą siekiant gauti dviženklią
              investicinę grąžą.
            </p>
            <p>
              Konsultuoja žmones, kurių tikslas investuojant į nekilnojamąjį
              turtą gauti 10% ar didesnę investicinę grąžą.
            </p>
            <p>
              Kartu su verslo partneriu administruoja daugiau nei 30 NT objektų.
            </p>
            <p className="team-member-description-links">
              <a
                href="https://www.facebook.com/nt.administravimas"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SvgIconComponent title='facebook' />
              </a>
              <a
                href="https://www.youtube.com/@nuomosekspertas5805"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SvgIconComponent title='youtube' />
              </a>
            </p>
          </div>
        </div>
        <div className="team-member">
          <img
            src="/img/Deivis.jpg"
            alt="Deivis Tubelis"
            className="team-member-picture"
          />
          <div className="team-member-description">
            <div className="team-member-description-header">
              <div>
                <p>Apie</p>
                <h2>Deivis Tubelis</h2>
              </div>
              <img
                src="/img/Deivis-portrait.png"
                alt="Deivis Tubelis"
                className="team-member-description-picture"
              />
            </div>
            <p>Vienas iš prekinio ženklo „Nuomos Ekspertas” įkūrėjų.</p>
            <p>
              Statybų srities patirties semiasi nuo 2002m., kuomet pradėjo
              karjerą rąstinių namų gamybos ir statybos įmonėje.
            </p>
            <p>
              2005m. įstojo į KTU statybos inžinerijos fakultetą ir dar studijų
              metais prisijungė prie studijų draugo, bei pakeitė profesinę
              kryptį, pradėdamas individualių namų savarankišką veiklą. 2010m.
              baigė studijas įgydamas Statybos inžinerijos bakalauro laipsnį.
            </p>
            <p>
              Per beveik 20 metų gautas teorines ir praktines žinias, šiuo metu
              pritaiko investicinių projektų planavimo ir įrengimos etapuose.
            </p>
            <p>
              Kartu su verslo partneriu administruoja daugiau nei 30 NT objektų.
            </p>
            <p className="team-member-description-links">
              <a
                href="https://www.facebook.com/nuomosekspertas"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SvgIconComponent title='facebook' />
              </a>
              <a
                href="https://www.youtube.com/@nuomosekspertas5805"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SvgIconComponent title='youtube' />
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamPage;
