import React from "react";
import "./footer.css";
import SvgIconComponent from "../Svg-Icon/SvgIconComponent";

export default function Footer() {
  const emailAddress = "nuomosekspertas@gmail.com";
  const mariausPhone = "+37061652581";
  const deivioPhone = "+37061130550";
  return (
    <>
      <div className="footer">
        <div className="footer-container">
          <div className="left-footer">
            <div>
              <h3>MB "ROMARA"</h3>
            </div>
            <div>
              <h3>Įm.K. 303464432</h3>
            </div>
            <div>
              <h3>D. Tarabildienės 9, LT-47252 Kaunas</h3>
            </div>
            <div className="social-icons">
              <a
                href="https://www.facebook.com/nt.administravimas"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SvgIconComponent title='facebook' />
              </a>
              <a
                href="https://www.instagram.com/nuomos_ekspertas/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SvgIconComponent title='instagram' />
              </a>
              <a
                href="https://www.youtube.com/@nuomosekspertas5805"
                target="_blank"
                rel="noopener noreferrer"
              >
                <SvgIconComponent title='youtube' />
              </a>
            </div>
          </div>
          <div className="right-footer">
            <a href={`tel:${mariausPhone}`} className="footer-links no-wrap">
              <span className="svg-container">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                </svg>
              </span>
              <span className="no-wrap">MARIUS LEPEŠKA</span>
              <span className="no-wrap">+370 616 52581</span>
            </a>
            <a href={`tel:${deivioPhone}`} className="footer-links no-wrap">
              <span className="svg-container">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                </svg>
              </span>
              <span className="no-wrap">DEIVIS TUBELIS</span>
              <span className="no-wrap">+370 611 30550</span>
            </a>
            <a href={`mailto:${emailAddress}`} className="footer-links no-wrap">
              <span className="svg-container">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
                </svg>
              </span>
              <span className="no-wrap">nuomosekspertas@gmail.com</span>
            </a>
          </div>
        </div>
      </div>
      <div className="bottom-footer">
        <span className="no-wrap">Copyright © 2024 Nuomos Ekspertas</span> |{" "}
        <span className="no-wrap">Powered by Nuomos Ekspertas</span>
      </div>
    </>
  );
}
