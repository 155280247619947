import React, { useLayoutEffect } from "react";
import "./homePage.css";
import { Link } from "react-router-dom";

const HomePage = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="home-page-container">
      <div className="shader">
        <img
          src="/img/037_WEB_Deivis_ir_Marius.webp"
          alt="background"
          className="enlarging--background"
        />
        <div className="home-page-title-container">
          <div className="home-page-title">
            <h1>nt nuoma be rizikos</h1>
            <h3>Nuomos rūpesčius palikite mums</h3>
            <Link
              to="/contacts"
              href="#"
              className="designed-button"
              role="button"
            >
              <span role="button">susisiekite</span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="designed-button-icon"
              >
                <path d="M168.2 384.9c-15-5.4-31.7-3.1-44.6 6.4c-8.2 6-22.3 14.8-39.4 22.7c5.6-14.7 9.9-31.3 11.3-49.4c1-12.9-3.3-25.7-11.8-35.5C60.4 302.8 48 272 48 240c0-79.5 83.3-160 208-160s208 80.5 208 160s-83.3 160-208 160c-31.6 0-61.3-5.5-87.8-15.1zM26.3 423.8c-1.6 2.7-3.3 5.4-5.1 8.1l-.3 .5c-1.6 2.3-3.2 4.6-4.8 6.9c-3.5 4.7-7.3 9.3-11.3 13.5c-4.6 4.6-5.9 11.4-3.4 17.4c2.5 6 8.3 9.9 14.8 9.9c5.1 0 10.2-.3 15.3-.8l.7-.1c4.4-.5 8.8-1.1 13.2-1.9c.8-.1 1.6-.3 2.4-.5c17.8-3.5 34.9-9.5 50.1-16.1c22.9-10 42.4-21.9 54.3-30.6c31.8 11.5 67 17.9 104.1 17.9c141.4 0 256-93.1 256-208S397.4 32 256 32S0 125.1 0 240c0 45.1 17.7 86.8 47.7 120.9c-1.9 24.5-11.4 46.3-21.4 62.9zM144 272a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm144-32a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm80 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" />
              </svg>
            </Link>
          </div>
        </div>

        <div className="home-page-services-container">
          <h3 className="centered-title">Paslaugos</h3>
          <div className="home-page-services-choises">
            <Link to="/services" id="administration" role="button">
              <div className="shadow">Ilgalaikės nuomos administravimas</div>
            </Link>
            <Link to="/contacts" id="consultations" role="button">
              <div className="shadow">Konsultavimas</div>
            </Link>
          </div>
        </div>
        <div className="home-page-feedback-container">
          <h3 className="centered-title">Ką kalba klientai</h3>
          <div className="home-page-feedback">
            <div>
              <div>
                "Bendradarbiaujam jau 2 metus. Aukšta kompetencija, greiti
                atsakymai ir jokių problemų. Rekomenduoju."
              </div>
              <a
                href="https://www.facebook.com/ricardas.kunevicius"
                target="_blank"
                rel="noopener noreferrer"
                className="feedback-picture"
              >
                <img
                  src="/img/Untitled-design-51-150x150.png"
                  alt="Ričardas Kunevičius"
                />
                Ričardas Kunevičius
              </a>
            </div>
            <div>
              <div>"Puikiai dirba! Rekomenduoju."</div>
              <a
                href="https://www.facebook.com/marius.jucikas"
                target="_blank"
                rel="noopener noreferrer"
                className="feedback-picture"
              >
                <img
                  src="/img/Untitled-design-50-150x150.png"
                  alt="Marius Jucikas"
                />
                Marius Jucikas
              </a>
            </div>
            <div>
              <div>
                "Aukštos kompetencijos savo srities ekspertai, kuriems patikėjus
                darbą yra ramu, nes žinai, kad viskas bus atlikta laiku ir
                profesionaliai. Labai rekomenduoju!"
              </div>
              <a
                href="https://www.facebook.com/mindaugas.snarskis.5"
                target="_blank"
                rel="noopener noreferrer"
                className="feedback-picture"
              >
                <img
                  src="/img/Untitled-design-49-283x300.png"
                  alt="Mindaugas Snarskis"
                />
                Mindaugas Snarskis
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
