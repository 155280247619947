import React from "react";
import { BrowserRouter } from "react-router-dom";
import Header from "../Header/Header";
import Main from "../Router/Main";
import Footer from "../Footer/Footer";
import "./app.css";

export default function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Header />
        <Main />
        <Footer />
      </BrowserRouter>
    </div>
  );
}
