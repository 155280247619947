import React, { useState, useLayoutEffect } from "react";
import "./contactsPage.css";

const ContactsPage = () => {
  const emailAddress = "nuomosekspertas@gmail.com";
  const mariausPhone = "+370 616 52581";
  const deivioPhone = "+370 611 30550";
  const [formData, setFormData] = useState({
    name: "",
    emailOrPhone: "",
    message: "",
    errorMessage: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, emailOrPhone, message } = formData;
    // Regular expressions for email and phone validation
    const emailPattern = /^\S+@\S+\.\S+$/;
    const phonePattern = /^\d{10,}$/;

    if (emailPattern.test(emailOrPhone) || phonePattern.test(emailOrPhone)) {
      try {
        const response = await fetch("/order/mailer.php", {
          method: "POST",
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify({ name, emailOrPhone, message }),
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        setFormData({
          name: "",
          emailOrPhone: "",
          message: "",
          errorMessage:
            "Žinutė sėkmingai išsiūsta. Susisieksime su Jumis netrukus.",
        });
      } catch (error) {
        setFormData({
          ...formData,
          errorMessage: "Iškilo ryšio problema, pabandykite vėliau dar kartą.",
        });
      }
    } else {
      setFormData({
        ...formData,
        errorMessage: "Patikrinkite ar teisingai įrašėte kontaktinius duomenis",
      });
    }
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contacts-page-container">
      <img
        src="/img/contacts-neon.png"
        alt="background"
        className="enlarging--background"
      />
      <div className="shader">
        <div className="contacts-page-content">
          <h1 className="half-screen-heading">KONTAKTAI</h1>
          <div className="contacts-page-modules">
            <div className="contact-page-form">
              <h2>Palikite kontaktus</h2>
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  id="name"
                  name="name"
                  required
                  placeholder="Jūsų vardas"
                  value={formData.name}
                  onChange={handleChange}
                />
                <input
                  type="text"
                  id="emailOrPhone"
                  name="emailOrPhone"
                  required
                  placeholder="elektroninis paštas arba telefono numeris"
                  value={formData.emailOrPhone}
                  onChange={handleChange}
                />
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  required
                  placeholder="parašykite savo žinutę"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
                {formData.errorMessage && <div>{formData.errorMessage}</div>}
                <input
                  type="submit"
                  value="Siųsti"
                  className="designed-button"
                />
              </form>
            </div>
            <div className="contacts-page-contacts-container">
              <h2>Susisiekite</h2>
              <a href={`mailto:${emailAddress}`} className="contacts-page-link">
                <span className="contacts-page-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
                  </svg>
                </span>
                <span className="padding-left-1rem">
                  nuomosekspertas@gmail.com
                </span>
              </a>
              <div className="contacts-page-contacts-list">
                <div className="contacts-page-team-member">
                  <img
                    src="/img/Marius-portrait.png"
                    alt="Marius Lepeška"
                    className="contacts-page-member-picture"
                  />
                  <a href={`tel:${mariausPhone}`}>
                    <h2 className="no-wrap">{mariausPhone}</h2>
                  </a>
                </div>
                <div className="contacts-page-team-member">
                  <img
                    src="/img/Deivis-portrait.png"
                    alt="Deivis Tubelis"
                    className="contacts-page-member-picture"
                  />
                  <a href={`tel:${deivioPhone}`}>
                    <h2 className="no-wrap">{deivioPhone}</h2>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactsPage;
