import React, { useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import "./pricePage.css";

const PricePage = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="price-page-container">
      <img
        src="/img/prices-background.jpg"
        alt="background"
        className="enlarging--background"
      />
      <div className="shader">
        <div className="price-page-content">
          <h1 className="full-screen-heading">KAINOS</h1>
          <h2>Paslaugų paketai</h2>
          <div className="price-page-choises">
            <div className="standart-choise">
              <div className="price-page-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                  <path d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM388.6 312.3l23.7 138.4L288 385.4l-124.3 65.3 23.7-138.4-100.6-98 139-20.2 62.2-126 62.2 126 139 20.2-100.6 98z" />
                </svg>
              </div>
              <div>STANDARTINIS paketas</div>
              <div>Vieno mėnesio nuomos kaina</div>
              <div>Vienkartinis. Be administravimo</div>
              <hr />
              <ul>
                <li className="checkmark-bullet">Profesionali fotosesija</li>
                <li className="checkmark-bullet">
                  Nuomos skelbimo kūrimas ir publikavimas
                </li>
                <li className="checkmark-bullet">
                  Profesionalios sutarties parengimas
                </li>
                <li className="checkmark-bullet">Nuomininkų atranka</li>
                <li className="checkmark-bullet">
                  Sutarties pasirašymo kontrolė
                </li>
                <li className="cross-bullet">Buto fizinės būklės priežiūra</li>
                <li className="cross-bullet">Mokesčių mokėjimų kontrolė</li>
                <li className="cross-bullet">Komunikacija su nuomininkais</li>
                <li className="cross-bullet">Gedimų šalinimo organizavimas</li>
              </ul>
              <Link to="/contacts" className="designed-button" role="button">
                <span>GAUTI PASIŪLYMĄ</span>
              </Link>
            </div>
            <div className="premium-choise">
              <div className="price-page-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                  <path d="M464 0H112c-4 0-7.8 2-10 5.4L2 152.6c-2.9 4.4-2.6 10.2 .7 14.2l276 340.8c4.8 5.9 13.8 5.9 18.6 0l276-340.8c3.3-4.1 3.6-9.8 .7-14.2L474.1 5.4C471.8 2 468.1 0 464 0zm-19.3 48l63.3 96h-68.4l-51.7-96h56.8zm-202.1 0h90.7l51.7 96H191l51.6-96zm-111.3 0h56.8l-51.7 96H68l63.3-96zm-43 144h51.4L208 352 88.3 192zm102.9 0h193.6L288 435.3 191.2 192zM368 352l68.2-160h51.4L368 352z" />
                </svg>
              </div>
              <div>PREMIUM paketas</div>
              <div>nuo 10 iki 15% nuomos kainos</div>
              <div>Mėnesinis. Su administravimu</div>
              <hr />
              <ul>
                <li className="checkmark-bullet">Profesionali fotosesija</li>
                <li className="checkmark-bullet">
                  Nuomos skelbimo kūrimas ir publikavimas
                </li>
                <li className="checkmark-bullet">
                  Profesionalios sutarties parengimas
                </li>
                <li className="checkmark-bullet">Nuomininkų atranka</li>
                <li className="checkmark-bullet">
                  Sutarties pasirašymo kontrolė
                </li>
                <li className="checkmark-bullet">
                  Buto fizinės būklės priežiūra
                </li>
                <li className="checkmark-bullet">Mokesčių mokėjimų kontrolė</li>
                <li className="checkmark-bullet">
                  Komunikacija su nuomininkais
                </li>
                <li className="checkmark-bullet">
                  Gedimų šalinimo organizavimas
                </li>
              </ul>
              <Link to="/contacts" className="designed-button" role="button">
                <span>GAUTI PASIŪLYMĄ</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricePage;
