import React from "react";
import { useMediaQuery } from "react-responsive";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./header.css";

export default function Header() {
  const isDesktop = useMediaQuery({ minWidth: 900 });
  const isTabletOrMobile = useMediaQuery({ maxWidth: 899 });
  const [menuActive, setMenuActive] = useState(false);
  const [activeHeader, setActiveHeader] = useState(false);
  const menu = [
    { title: "Pradžia", route: "" },
    { title: "Komanda", route: "team" },
    { title: "Kainos", route: "prices" },
    { title: "Paslaugos", route: "services" },
    { title: "Kontaktai", route: "contacts" },
  ];

  const toggleMenu = () => {
    setMenuActive((prevIsActive) => !prevIsActive);
  };

  const activateHeader = () => {
    if (window.scrollY >= 50) {
      setActiveHeader(true);
    } else {
      setActiveHeader(false);
    }
  };

  window.addEventListener("scroll", activateHeader);

  return (
    <div
      className={
        activeHeader ? "header-container active-header" : "header-container inactive-header" 
      }
    >
      <div className={activeHeader ? 'logo-container' : 'logo-container padding1'}>
        <Link to="/" className="title">
          <img
            className="logo-svg"
            src={`${process.env.PUBLIC_URL}/img/romara-logo.svg`}
            alt="Romara Logo"
          />
        </Link>
        {isDesktop && (
          <nav className="desktop-menu-list">
            {menu.map((item) => (
              <NavLink
                to={item.route}
                className="desktop-list-item"
                activeClassName="active"
                onClick={toggleMenu}
                key={item.route}
              >
                {item.title}
              </NavLink>
            ))}
          </nav>
        )}
        {isTabletOrMobile && (
          <div className={menuActive ? "hamburger active" : "hamburger inactive"} onClick={toggleMenu}>
            <div className="hamburger-stroke"></div>
          </div>
        )}
      </div>
      {isTabletOrMobile && (
        <nav className={menuActive ? "mobile-menu-list visible" : "mobile-menu-list invisible"}>
          {menu.map((item) => (
            <NavLink
              to={item.route}
              className="mobile-list-item"
              activeClassName="active"
              onClick={toggleMenu}
              key={item.route}
            >
              {item.title}
            </NavLink>
          ))}
        </nav>
      )}
    </div>
  );
}
